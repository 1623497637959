// Start Bootstrap
@import 'bootstrap/scss/functions';
// Bootstrap variable overrides
@import 'custom';

@import "bootstrap/scss/variables";
// Add Bootstrap map overrides here
// Rest of Bootstrap
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
//@import "bootstrap/scss/accordion";
//@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/offcanvas";
//@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";

// A11y: override Bootstrap to increase color contrast
.bg-light .nav-link.disabled {
  color: var(--bs-gray-700);
}

// Override Bootstrap's underlining
a, .btn-link {
  text-decoration: none;
}

a:not(.btn):not(.nav-link):not(.list-group-item-link):hover, .btn-link:hover {
  text-decoration: underline;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.6);
}

.form-label-required::after {
  content: "*";
  color: var(--bs-danger);
  margin-left: .2em;
}

.table-nonfluid {
  width: auto !important;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    .border-#{$breakpoint}-none {
      border: none !important;
    }
    .border-#{$breakpoint}-start-none {
      border-left: none !important;
    }
    .border-#{$breakpoint}-end-none {
      border-right: none !important;
    }
    .border-#{$breakpoint}-top-none {
      border-top: none !important;
    }
    .border-#{$breakpoint}-bottom-none {
      border-bottom: none !important;
    }
  }
}
