// A11y: override Bootstrap vars to increase color contrast
$breadcrumb-active-color: #495057; // gray-700
// primary default
$blue: #1B559B;
// info default
$cyan: #037386;
// success default
$green: #107527;
// danger default
$red: #DC1F31;
// warning default
$yellow: #EBA10A;
